import React, { useState } from 'react';
import { TextField, Button, Typography, Container } from '@mui/material';
import api from "../api";

const FileUploadForm = ({ fieldName, dataSet, propertyID }) => { // Accept fieldName as a prop
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null); // State for error message


  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleFileChange = (e) => {
    const fileList = Array.from(e.target.files); // Convert FileList to array
    setFiles(fileList);
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();

    // Create form data object
    const formData = new FormData();
    formData.append('description', description);
    formData.append('fieldName', fieldName);
    formData.append('propertyUUID', propertyID);
    formData.append('dataset', dataSet);

    // Append each file to form data
    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    try {
      // Send POST request using Axios
      const response = await api.post('/assetupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // Handle success response if needed
      console.log('Upload successful:', response.data);

      // Reset form fields and error message
      setDescription('');
      setFiles([]);
      setErrorMessage(null);
    } catch (error) {
      // Handle error
      console.error('Error uploading file:', error);
      console.log(error.response.data)
      if (error.response.data.data === "token expired"){
        setErrorMessage('Login expired - please log back in and try again');
        return 
      }

      // Set error message
      setErrorMessage('Error uploading file. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm">
      {errorMessage && (
        <Typography variant="body1" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      <Typography variant="h4" align="center" gutterBottom>
        File Upload Form for {fieldName} {/* Display the field name */}
      </Typography>
      <form onSubmit={handleUploadSubmit}>
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={handleDescriptionChange}
          margin="normal"
          variant="outlined"
        />
        <input
          accept="image/*,application/pdf"
          style={{ display: 'none' }}
          id="file-upload"
          name="files"
          type="file"
          multiple // Allow multiple files
          onChange={handleFileChange}
        />
        <label htmlFor="file-upload">
          <Button variant="contained" component="span">
            Upload Files
          </Button>
        </label>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          {files.length > 0 ? `${files.length} files selected` : 'No files chosen'}
        </Typography>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default FileUploadForm;
