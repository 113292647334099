import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import api from "../api";
import logo from '../assets/retrrologo.png';
// import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

function PropertyListPage() {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [filterText, setFilterText] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchData = async () => {
    try {
      const response = await api.get(`properties`);
      setData(response.data.data);
      setError('');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.data === "token expired") {
        navigate('/login', { state: { from: location.pathname } });
      } else {
        setError('Error fetching data');
        console.log(error.response.data.data);
      }
    }
  };

  const filteredData = data.filter(row =>
    row.propertyName.toLowerCase().includes(filterText.toLowerCase()) ||
    row.UPRN.toLowerCase().includes(filterText.toLowerCase()) ||
    row.address.toLowerCase().includes(filterText.toLowerCase()) ||
    row.buildingManager.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const clearFilter = () => {
    setFilterText('');
  };

  const columns = [
    { field: 'propertyName', headerName: 'Property Name', flex: 1, headerClassName: 'custom-header' },
    { field: 'UPRN', headerName: 'UPRN', flex: 1, headerClassName: 'custom-header' },
    { 
      field: 'address', 
      headerName: 'Address', 
      width: 250,
      headerClassName: 'custom-header' 
    },
    { field: 'buildingManager', headerName: 'Building Manager', flex: 1, headerClassName: 'custom-header' },
    { field: 'EPC', headerName: 'EPC', headerClassName: 'custom-header', width: 40, align: 'center' },
    { field: 'DEC', headerName: 'DEC', headerClassName: 'custom-header', width: 40, align: 'center' },
    { field: 'floorAreaM2', headerName: 'M2', width: 80, align: 'right', headerClassName: 'custom-header' },
    { field: 'builtYear', headerName: 'Year', width: 60, headerClassName: 'custom-header' },
    { field: 'plannedProjectsValue', headerName: 'PPV', width: 140, align: 'right', headerClassName: 'custom-header' },
  ];

  const getRowId = (row) => row.propertyUUID;

  const handleRowClick = (params) => {
    console.log(`UUID: ${params.row.propertyUUID}, Name: ${params.row.propertyName}`);
    navigate(`/buildinginfo/${params.row.propertyUUID}`); // Navigate to the property page with the UUID as the parameter
  };

  return (
    <Container style={{ paddingTop: '20px', width: '100%' }}>
      <Typography variant="h4" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
        <img src='https://seeklogo.com/images/P/portsmouth-city-council-logo-17728273E5-seeklogo.com.png' alt='pcc logo' style={{ float: 'left', height: '50px' }} />
        <span style={{ textAlign: 'center', flex: '1' }}>Property Portfolio Dashboard</span>
        <img src={logo} alt='' style={{ float: 'right', height: '60px', marginLeft: '100px' }} />
      </Typography>
      {error && <Typography variant="body1" color="error">{error}</Typography>}
      <TextField
        label="Quick Filter"
        variant="outlined"
        size="small"
        value={filterText}
        onChange={handleFilterChange}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              🔍
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {filterText && (
                <IconButton onClick={clearFilter} size="small">
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <div style={{ height: "80vh", outline: 'none' }}>
        <DataGrid
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            width: '100%',
          }}
          rows={filteredData}
          columns={columns}
          getRowId={getRowId} // Set row ID as propertyUUID
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 15 } },
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              propertyUUID: false,
            },
          }}
          pageSizeOptions={[15, 25, 50]}
          pageSize={10} // Set default number of rows per page to 10
          disableSelectionOnClick // Disable cell selection
          disableSelection
          onRowClick={handleRowClick} // Attach row click event handler
        />
      </div>
    </Container>
  );
}

export default PropertyListPage;
