import { useLocation } from 'react-router-dom';
// PrivateRoute.js
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';

export default function PrivateRoute({ children }) {
  const location = useLocation();
  const { isLoggedIn } = useAuth();


  return isLoggedIn ? <Outlet /> : <Navigate to="/login"  replace state = {{from: location}}/>
}
