import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Button, Modal, InputAdornment } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddUserForm from './AddUserForm'; // Import your AddUserForm component

function QueryUsersPage() {
  const [usersList, setUsersList] = useState([]);
  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate
  const [openAddUserModal, setOpenAddUserModal] = useState(false); // State for controlling the Add User modal
  const [editUserData, setEditUserData] = useState(null); // State for holding user data to edit

  useEffect(() => {
    // Load users list when component mounts
    handleQueryUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddUserClick = () => {
    setOpenAddUserModal(true);
    setEditUserData(null); // Reset editUserData when adding a new user
  };

  const handleCloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  const handleEditUserClick = (userData) => {
    setOpenAddUserModal(true);
    setEditUserData(userData); // Set editUserData when editing a user
  };

  const handleQueryUsers = async () => {
    try {
      const apiKey = localStorage.getItem('accessToken');
      const response = await axios.get('/userslist', {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      });
      setUsersList(response.data.data);
      setError('');
      filterUsers(filterText, response.data.data);
    } catch (error) {
      setUsersList([]);
      setFilteredUsersList([]);
      console.log(error.response.data);
      setError(error.response.data.message);
    }
  };

  const handleRowClick = (uid) => {
    // Navigate to user details page with the user ID as a parameter
    navigate(`/user/${uid}`);
  };

  const handleFilterChange = (e) => {
    const text = e.target.value;
    setFilterText(text);
    filterUsers(text, usersList);
  };

  const filterUsers = (text, userList) => {
    const filteredList = userList.filter(user => {
      // Check if any of the columns contain the filter text
      return Object.values(user).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(text.toLowerCase())
      );
    });
    setFilteredUsersList(filteredList);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Users Page
      </Typography>
      <TextField
        label="Quick Filter"
        fullWidth
        value={filterText}
        onChange={handleFilterChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              🔍
            </InputAdornment>
          ),
        }}
      />
      {error && <Typography variant="body1" color="error">{error}</Typography>}
      {filteredUsersList.length > 0 && (
        <TableContainer component={Paper} style={{ marginTop: '2rem' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell align="center">
                <Button variant="contained" color="primary" onClick={handleAddUserClick} style={{ width: '70px', marginBottom: '1rem' }}>
        Add 
      </Button>
 
                </TableCell> {/* Add a column for actions */}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsersList.map((user, index) => (
                <TableRow key={user.id} onClick={() => handleRowClick(user.user_uuid)} style={{ cursor: 'pointer', backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5' }}>
                  <TableCell>{user.user_email}</TableCell>
                  <TableCell>{user.first_name}</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                  <TableCell align="center">
                    <Button variant="outlined" color="primary" onClick={() => handleEditUserClick(user)}>Edit</Button> {/* Add edit button */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* Modal for adding/editing a user */}
      <Modal
        open={openAddUserModal}
        onClose={handleCloseAddUserModal}
        aria-labelledby="add-user-modal-title"
        aria-describedby="add-user-modal-description"
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Paper elevation={3} style={{ padding: '2rem', minWidth: '300px', backgroundColor: 'white' }}>
            <AddUserForm onClose={handleCloseAddUserModal} mode={editUserData ? 'edit' : 'add'} userData={editUserData} /> {/* Pass mode and userData props */}
          </Paper>
        </div>
      </Modal>
    </Container>
  );
}

export default QueryUsersPage;
