/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, AppBar, Tabs, Tab, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import api from '../api'; // Assuming api module is located in the '../api' directory
import MuiAlert from '@mui/material/Alert';
import TextInputField from '../uicomponents/TextInputField';
import ProjectSuppliersGrid from './ProjectSuppliersGrid';
//import SelectBox from '../uicomponents/SelectBox';
import { TextArea } from 'semantic-ui-react';

// import ImageCarouselPopup from './ImageCarouselPopup';
import DataList from "./DataList"

import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/retrrologo.png';
import TextareaAutosize from '@mui/material/TextareaAutosize';




const ProjectForm = () => {

  // const [popupOpen, setPopupOpen] = useState(false);
  // const [carouselImages, setCarouselImages] = useState([]);

  // const showImageCarousel = (imageUrls) => {
  // setCarouselImages(imageUrls);
  // setPopupOpen(true);
  // };
  const navigate = useNavigate();
  const location = useLocation();
  const { projectID } = useParams();
    const [formData, setFormData] = useState({
    projectRef: '',
    projectID: '',
    projectUUIS: '',
    projectName: '',
    description: '',
    propertyName: '',
    currentStatus: '',
    fullAddress: '', // Add this line
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    postcode: "",
    country: "",
    propertyDescription: '', // Add this line
    costEstimate: '', // Add this line
    estimatedTimeToDeliver: '', // Add this line
    estimatedAnnualOPEXSaving: '',
    estimatedEmissionsReduction: '',
    invasiveness: '',
    notes: '',
    status: [],
  });

  const [userRights, setUserRights] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);






  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    try {
      const response = await api.get(`/project/${projectID}`);
      const data = response.data.data;
      setFormData(data);
      setUserRights(response.data.rights);

    } catch (error) {
      console.error('Error fetching form data:', error);
      if (error.response && error.response.data && error.response.data.data === "token expired") {
        navigate('/login', { state: { from: location.pathname } });
      } else {
        setError('Error fetching data');
        console.log(error.response.data.data);
      }
    }
  };
  const handleStatusChange = (e) => {
    console.log(e)
  }
  const handleInputChange = (e) => {

    const { name, value } = e.target;
    console.log(name);
    console.log(value);

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {

    fetchData()
    setEditMode(false);
  };


  const handleSave = async () => {
    try {
      api.put(`/project/${projectID}`, formData);
      console.log("Dummy save project");
      setEditMode(false);
      console.log(formData)
      //console.log(formData)
      //fetchData();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };


  const handleDelete = async () => {
    setOpenDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`/project/${projectID}`);
      setOpenDeleteModal(false);
      fetchData();
    } catch (error) {
      setOpenDeleteModal(false);
      setError('Error deleting data.');
      setSnackbarOpen(true);
    }
  };


  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Container style={{ paddingTop: '20px', width: '100%' }}>
      <Typography variant="h4" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
        <img src='https://seeklogo.com/images/P/portsmouth-city-council-logo-17728273E5-seeklogo.com.png' alt='pcc logo' style={{ float: 'left', height: '50px' }} />
        <span style={{ textAlign: 'center', flex: '1' }}>Project {formData.projectRef}</span>
        <img src={logo} alt='' style={{ float: 'right', height: '60px', marginLeft: '100px' }} />
      </Typography>
      {error && (
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
      <div style={{ width: '100%', height: '100%', minHeight: '60px', marginBottom: '5px' }}>
        <div style={{ width: '540px', float: 'left' }}>
          <TextInputField
            name="projectRef"
            value={formData.projectRef}
            onChange={handleInputChange}
            label="Reference"
            disabled={!editMode}
            width="125"
            backgroundColor={'lightyellow'}
            nopad
          />
          <TextInputField
            name="projectID"
            value={formData.projectID}
            onChange={handleInputChange}
            label="ID"
            disabled={!editMode}
            width="125"
            backgroundColor={'lightyellow'}
            nopad
            clear
          />
          <TextInputField
            name="projectName"
            value={formData.projectName}
            onChange={handleInputChange}
            label="Name"
            disabled={!editMode}
            width="400"
            backgroundColor={'lightyellow'}

          />
          <TextInputField
            name="buildingManager"
            value={formData.projectManagerFullName}
            onChange={handleInputChange}
            label="Project Manager"
            disabled={!editMode}
            width="200"
            backgroundColor="lightyellow"
            nopad
            clear
          />

        </div>
        <div style={{ width: '500px', float: 'left' }}>

          <TextInputField
            name="uprn"
            value={formData.UPRN}
            onChange={handleInputChange}
            label="UPRN"
            disabled={!editMode}
            width="120"
            backgroundColor="lightyellow"
            nopad
            nodocs
            clear
          />
          <TextInputField
            name="buildingName"
            value={formData.propertyName}
            onChange={handleInputChange}
            label="Building Name"
            disabled={!editMode}
            width="200"
            backgroundColor="lightyellow"
            nopad
            nodocs
            clear
          />

          <TextInputField
            name="Status"
            value={formData.currentStatus}
            onChange={handleInputChange}
            label="Status"
            disabled={!editMode}
            width="300"
            backgroundColor="lightyellow"
            nopad
            nodocs
            clear
          />




        </div>
      </div>

      <AppBar position="static" sx={{ background: '#fff' }}>
        <Tabs value={tabValue} onChange={handleChangeTab}>
          <Tab label="Information" sx={{ backgroundColor: tabValue === 0 ? 'white' : '#f5f5f5' }} />
          <Tab label="Building" sx={{ backgroundColor: tabValue === 1 ? 'white' : '#f5f5f5' }} />
          <Tab label="Suppliers" sx={{ backgroundColor: tabValue === 2 ? 'white' : '#f5f5f5' }} />
          <Tab label="Notes" sx={{ backgroundColor: tabValue === 3 ? 'white' : '#f5f5f5' }} />
        </Tabs>
      </AppBar>
      {tabValue === 0 && (
        <>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
            <div style={{ paddingTop: '10px', width: '550px', float: 'left', minHeight: '450px' }}>
              <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px', margin: '10px', margintop: '90px', height: '300px' }}>
                <div style={{ marginTop: '-280px', marginRight: '10px', width: '90px', textAlign: 'right' }}>
                  Description
                </div>
                <div style={{ width: '410px', height: '300px' }}>
                  <TextareaAutosize
                    name="projectDescription"
                    value={formData.projectDescription}
                    onChange={handleInputChange}
                    maxRows={15} // Number of visible rows
                    cols={80} // Number of visible columns
                    disabled={!editMode}
                    style={{
                      padding: '5px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      width: '100%',
                      height: '280px',
                      resize: 'none', // Disable resizing
                      overflowY: 'auto', // Show scrollbars if content overflows
                    }}
                  />
                </div>

              </div>
              <TextInputField
                name="costEstimate"
                value={formData?.costEstimate}
                onChange={handleInputChange}
                label="Cost Estimate"
                width="140"
                disabled={!editMode}
                nopad
              />
              <TextInputField
                name="estimatedTimeToDeliver"
                value={formData.estimatedTimeToDeliver}
                onChange={handleInputChange}
                label="Duration"
                disabled={!editMode}
                width="120"
                nopad
                clear
              />
              <TextInputField
                name="estimatedAnnualOPEXSaving"
                value={formData?.estimatedAnnualOPEXSaving}
                onChange={handleInputChange}
                label="Est Annual OPEX saving"
                width="140"
                disabled={!editMode}
                nopad
              />
              <TextInputField
                name="estimatedEmissionsReduction"
                value={formData.estimatedEmissionsReduction}
                onChange={handleInputChange}
                label="Emissions Reduction "
                disabled={!editMode}
                width="120"
                nopad
                clear
              />
              <TextInputField
                name="invasiveness"
                value={formData.invasiveness}
                onChange={handleInputChange}
                label="Invasiveness"
                disabled={!editMode}
                width="120"
                nopad
                clear
              />


            </div>
            <div style={{ paddingTop: '10px', width: '550px', float: 'left', minHeight: '450px' }}>
              <DataList data={formData.status} editMode={editMode} onDataChange={handleStatusChange} />

            </div>
          </div>
        </>
      )}
      {tabValue === 1 && (
        <>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>
              
              <TextInputField
                name="addressLine1"
                value={formData.addressLine1}
                onChange={handleInputChange}
                label="Address"
                disabled={!editMode}
              />
              <TextInputField
                name="addressLine2"
                value={formData.addressLine2}
                onChange={handleInputChange}
                label=""
                disabled={!editMode}
              />
              <TextInputField
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                label=""
                disabled={!editMode}
              />
              <TextInputField
                name="county"
                value={formData.county}
                onChange={handleInputChange}
                label=""
                disabled={!editMode}
              />
              <TextInputField
                name="postcode"
                value={formData.postcode}
                onChange={handleInputChange}
                label=""
                disabled={!editMode}
              />




            </div>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px', margin: '0px', margintop: '90px', height: '300px' }}>

<div style={{ marginTop: '-270px', marginRight: '10px', width: '90px', textAlign: 'right' }}>
  Property Description
</div>
<div style={{ width: '410px', height: '300px' }}>
  <TextareaAutosize
    name="propertyDescription"
    value={formData.propertyDescription}
    onChange={handleInputChange}
    maxRows={15} // Number of visible rows
    cols={80} // Number of visible columns
    disabled={!editMode}
    style={{
      padding: '5px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      width: '100%',
      height: '280px',
      resize: 'none', // Disable resizing
      overflowY: 'auto', // Show scrollbars if content overflows
    }}
  />
</div>
</div>

            </div>
          </div>
        </>
      )}
      {tabValue === 2 && (
        <>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
           <ProjectSuppliersGrid projectUUID={formData.projectUUID} />

          </div>
        </>
      )}

      {tabValue === 3 && (
        <>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
          <TextArea
              placeholder="Notes"
              label="Notes"
              style={{ marginTop: '10px', width: '99%', height: '400px' }}
              // Add any necessary props and event handlers here
              disabled={!editMode}
              value={formData.notes}
            />
          </div>
        </>
          
      )}
      {((userRights.includes('update') || userRights.includes('edit')) && !editMode) && (
        <Button variant="contained" color="primary" onClick={handleEdit}>Edit</Button>
      )}
      {editMode && (
        <>
          <Button variant="contained" onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </>
      )}
      {userRights.includes('delete') && (
        <Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
      )}
      <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>Deleting this item cannot be undone.</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">Yes</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProjectForm;

