

import ContentCopyIcon from '../assets/docs.png';


function calcPad(width, nopad) {
  if (nopad === true) {
    return 30;
  } else {
    return 400 - width;
  }
}

function TextInputfield({ name, nodocs, camera, value, onChange, disabled, label, width, nopad, backgroundColor, clear, docs }) {

  // Use state in the component
  // For example:
  //console.log(state);
  const newwidth = width || 400;
  const padwidth = calcPad(newwidth, nopad);
  if (docs === 'none') {
    docs = false;
  };
  
  
  return (
    <div>
      <div style={{ width: `110px`, height: `40px`, float: 'left', marginRight: '5px', alignItems: 'center', textAlign: 'right' }}>{label}</div>
      <div style={{ width: `${newwidth}px`, textAlign: 'left', float: 'left' }}>
        <input
          type="text"
          disabled={disabled}
          value={value}
          name={name}
          onChange={onChange}
          style={{
            height: '40px',
            width: '100%',
            fontSize: '1.1em',
            borderRadius: '5px',
            border: '1px solid #ccc',
            boxSizing: 'border-box',
            padding: '5px',
            marginBottom: '10px',
            backgroundColor: backgroundColor,
          }}
        />
      </div>
      <div style={{ width: `${padwidth}px`, height: `40px`, paddingTop: '5px', textAlign: 'left', float: 'left' }}>
        {docs && (
          <img src={ContentCopyIcon} alt="Docs" onClick={(e) => camera(e, name)} />
        )}
        
      </div>
      {clear && (
        <div style={{ clear: 'both' }}></div>
      )}
       <div sx={{ minWidth: '900px' }}>
      </div>



    </div>
    
  );
}

export default TextInputfield;
