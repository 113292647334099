import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const Dashboard = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/dashboard');
        console.log(response.data)
        setUserData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {userData && (
        <div  sx={{ border: '2px solid #ADD8E6', padding: '10px' }}>
          <Typography variant="h5">Name: {userData.name}</Typography>
          <Typography variant="body1">Email: {userData.email}</Typography>
          <TableContainer>
            <Table sx={{ width: '200px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Roles</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.roles.map((role, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ width: '200px' }}>{role}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {userData && (
        <div>
          <Typography variant="h5">Total Projects Information</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={[
                { name: 'Total Projects', count: userData.projects.count },
                { name: 'Red Projects', count: userData.projects.red },
                { name: 'Green Projects', count: userData.projects.green },
              ]}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};


