import React from 'react';
import ContentCopyIcon from '../assets/docs.png';

function SelectBox({ name, options, value, onChange, disabled, label, width, backgroundColor, clear, camera, docs }) {
  return (
    <div>
      <div style={{ width: `110px`, height: `40px`, float: 'left', marginRight: '5px', alignItems: 'center', textAlign: 'right' }}>{label}</div>
      <div style={{ width: `${width || 400}px`, textAlign: 'left', float: 'left' }}>
        <select
          disabled={disabled}
          value={value}
          name={name}
          onChange={onChange}
          style={{
            height: '40px',
            width: '100%',
            fontSize: '1.1em',
            borderRadius: '5px',
            border: '1px solid #ccc',
            boxSizing: 'border-box',
            padding: '5px',
            marginBottom: '10px',
            backgroundColor: backgroundColor,
          }}>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
      <div style={{ width: `${width ? 30 : 430 - width}px`, height: `40px`, paddingTop: '5px', textAlign: 'left', float: 'left' }}>
        {docs && (
          <img src={ContentCopyIcon} alt="Docs" onClick={(e) => camera(e, name)} />
        )}
      </div>
      {clear && (
        <div style={{ clear: 'both' }}></div>
      )}
    </div>
  );
}

export default SelectBox;
