// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import QueryPage from './components/QueryPage';
import DataGridPage from './components/DataGridPage';
import QueryUsersPage from './components/QueryUsers';
import { AuthProvider } from './components/AuthContext';
import { AppBar, Toolbar, Typography } from '@mui/material';
import AuthButtons from './components/AuthButtons';
import PrivateRoute from './components/PrivateRoute';
import { Dashboard } from './components/Dashboard';
import {DynamicForm} from './components/DynamicForm';
import PropertyListPage from './components/PropertyListPage';
import ProjectListPage from './components/ProjectListPage';
import BuildingInfoPage from './components/BuildingInfo';
import PropertyView from "./components/PropertyView"
import ProjectView from "./components/ProjectView"
import EditUserForm from "./components/EditUserForm"
import SuppliersListPage from "./components/SuppliersListPage"
import SupplierView from "./components/SupplierView"

import FileUploadForm from "./components/FileUploadForm"

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                RetrroFit
              </Typography>
              <AuthButtons />
            </Toolbar>
          </AppBar>
          <Routes>
            <Route path="/" exact element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/df" element={<DynamicForm />} />
            <Route path="/pi/:propertyID" element={<BuildingInfoPage />} />
            <Route element={<PrivateRoute />}>
              <Route path="/query" element={<QueryPage />} />
              <Route path="/data" element={<DataGridPage />} />
              <Route path="/users" element={<QueryUsersPage />} />
              <Route path="/user/:userID" element={<EditUserForm />} />
              <Route path="/properties" element={<PropertyListPage />} />
              <Route path="/buildinginfo/:propertyID" element={<PropertyView />} />
              <Route path="/uploadasset" element={<FileUploadForm />} />
              <Route path="/projects" element={<ProjectListPage />} />
              <Route path="/projectinfo/:projectID" element={<ProjectView />} />
              <Route path="/suppliers" element={<SuppliersListPage />} />
              <Route path="/supplier/:supplierUUID" element={<SupplierView />} />

            </Route>
            
            
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
