import React, { useState, useEffect } from 'react';
import api from "../api";
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';


const SupplierProjectsGrid = ({ supplierUUID }) => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const handleRowClick = (params) => {
    console.log(`UUID: ${params.row.project_uuid}`);
    navigate(`/projectinfo/${params.row.project_uuid}`); // Navigate to the property page with the UUID as the parameter
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await api.get(`supplier/${supplierUUID}/projects`);
        if (response.data.status === 'success') {
          setProjects(response.data.data);
        } else {
          console.error('Failed to fetch projects:', response.data);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    if (supplierUUID) {
      fetchProjects();
    }
  }, [supplierUUID]);

  const columns = [
    { field: 'project_name', headerName: 'Project Name', width: 200 },
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'current_status', headerName: 'Status', width: 150 },
    { field: 'first_name', headerName: 'First Name', width: 150 },
    { field: 'last_name', headerName: 'Last Name', width: 150 },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={projects}
        columns={columns}
        autoHeight
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        pageSizeOptions={[15, 25, 50]}
        pageSize={10} // Set default number of rows per page to 10
        disableSelectionOnClick // Disable cell selection
        disableSelection
        onRowClick={handleRowClick} // Attach row click event handler

        getRowId={(row) => row.project_uuid}
      />
    </div>
  );
};

export default SupplierProjectsGrid;
