/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import api from "../api";
import logo from '../assets/retrrologo.png';
import './DataGridPage.css';


function DataGridPage() {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.get(`userdata`);

      setData(response.data.data);
      setError('');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.data === "token expired") {
        navigate('/login', { state: { from: location.pathname } });
      } else {
        setError('Error fetching data');
        console.log(error.response.data.data);
      }
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex:1, headerClassName: 'custom-header' },
    { field: 'firstName', headerName: 'First Name', flex:1, headerClassName: 'custom-header' },
    { field: 'lastName', headerName: 'Last Name', flex:1, headerClassName: 'custom-header' },
    { field: 'email', headerName: 'Email', flex:1, headerClassName: 'custom-header' },
    { field: 'department', headerName: 'Department', flex:1, headerClassName: 'custom-header' },
  ];

  const handleRowClick = (params) => {
    console.log(`UUID: ${params.row.id}, First Name: ${params.row.firstName}`);
  };

  return (
    <Container style={{  paddingTop: '20px' }}>
      <Typography variant="h4" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
        <img src='https://seeklogo.com/images/P/portsmouth-city-council-logo-17728273E5-seeklogo.com.png' alt='pcc logo' style={{ float: 'left', height: '50px' }} />
        <span style={{ textAlign: 'center', flex: '1' }}>Property Portfolio Dashboard</span>
        <img src={logo} alt='' style={{ float: 'right', height: '60px', marginLeft: '100px' }} />
      </Typography>

      {error && <Typography variant="body1" color="error">{error}</Typography>}
      <div style={{ height: "80vh",  outline: 'none' }}>
        <DataGrid
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            width: '100%',
          }}
          rows={data}
          columns={columns}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 15 } },
          }}
          pageSizeOptions={[15, 25, 50]}
          pageSize={10} // Set default number of rows per page to 10
          disableSelectionOnClick // Disable cell selection
          disableSelection
          onRowClick={handleRowClick} // Attach row click event handler
        />
      </div>
    </Container>
  );
}

export default DataGridPage;
