import React, { useState, useEffect } from 'react';
import api from "../api";
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';


const ProjectSuppliersGrid = ({ projectUUID }) => {
  const [suppliers, setSuppliers] = useState([]);
  const navigate = useNavigate();
  const handleRowClick = (params) => {
    console.log(`SupplierUUID: ${params.row.supplier_uuid}`);
    navigate(`/supplier/${params.row.supplier_uuid}`); // Navigate to the property page with the UUID as the parameter
  };

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await api.get(`project/${projectUUID}/suppliers`);
        if (response.data.status === 'success') {
          setSuppliers(response.data.data);
        } else {
          console.error('Failed to fetch projects:', response.data);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    if (projectUUID) {
      fetchSuppliers();
    }
  }, [projectUUID]);

  const columns = [
    { field: 'supplierName', headerName: 'Supplier', width: 200 }, 
    { field: 'first_name', headerName: 'First Name', width: 150 },
    { field: 'last_name', headerName: 'Last Name', width: 150 },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={suppliers}
        columns={columns}
        autoHeight
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        pageSizeOptions={[15, 25, 50]}
        pageSize={10} // Set default number of rows per page to 10
        disableSelectionOnClick // Disable cell selection
        disableSelection
        onRowClick={handleRowClick} // Attach row click event handler

        getRowId={(row) => row.supplier_uuid}
      />
    </div>
  );
};

export default ProjectSuppliersGrid;
