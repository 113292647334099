import React, { useState, useEffect } from 'react';
import api from '../api'; // Assuming api module is located in the '../api' directory
import { Modal, Typography, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function ImagePopup({ propertyId, datasetId, fieldName, onClose }) {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);

  const handleDownload = (imageUrl, fileName) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePrevClick = () => {
    setIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_BASE_URL + `/assets/${propertyId}/${datasetId}/${fieldName}`;
        const response = await api.get(url);
        if (response.data.data.length === 0){
            console.log("No images found");
            onClose();
            alert("No images found");
        }
        setImages(response.data.data);
      } catch (error) {
        console.error('Error fetching images:', error.message);
      }
    };

    fetchData();
  }, [propertyId, datasetId, fieldName, onClose]);

  return (
    <Modal open={images.length > 0} onClose={onClose}>
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '500px', minWidth: '500px', marginTop: '50px' }}>
          <div style={{ maxWidth: '90%', maxHeight: '90%', overflow: 'auto', backgroundColor: 'white', borderRadius: '10px', padding: '20px' }}>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={6} md={4} lg={26}>
                <Card style={{ minWidth: '400px', minHeight: '400px', marginTop: '10px' }}>
                  <CardMedia
                    component="img"
                    sx={{ maxWidth: '100%', maxHeight: '400px', height: 'auto', objectFit: 'cover' }}
                    image={`${process.env.REACT_APP_BASE_URL}/asset/${images[index]?.asset_uuid}`}
                    alt={images[index]?.asset_file_name}
                    crossOrigin="anonymous"
                  />
                  <CardContent>
                    <Typography variant="subtitle1">Uploaded by: {images[index]?.uploadedby}</Typography>
                    <Typography variant="body2">Description: {images[index]?.asset_metadata.description}</Typography>
                    <Typography variant="body2">Lat: {images[index]?.asset_metadata.lat}</Typography>
                    <Typography variant="body2">Lng: {images[index]?.asset_metadata.lng}</Typography>
                    <Typography variant="body2">Timestamp: {images[index]?.asset_metadata.timeStamp}</Typography>
                    <Button
                      onClick={() => handleDownload(`${process.env.REACT_APP_BASE_URL}/asset/${images[index]?.asset_uuid}`, images[index]?.asset_file_name)}
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '10px' }}
                    >
                      View/Download Fullsize image
                    </Button>
                    <Button
                      onClick={onClose}
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '10px' }}
                    >
                      Close
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <Button onClick={handlePrevClick} startIcon={<NavigateBeforeIcon />} color="primary">
                Previous
              </Button>
              <Button onClick={handleNextClick} endIcon={<NavigateNextIcon />} color="primary">
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ImagePopup;
