/* eslint-disable react-hooks/exhaustive-deps */
// BuildingInfoPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, TextField, Button} from '@mui/material';

import logo from '../assets/retrrologo.png';

function BuildingInfoPage() {
  const { buildingID } = useParams();
  const [buildingInfo, setBuildingInfo] = useState(null);
  const [editedBuildingInfo, setEditedBuildingInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(buildingID);
        const response = await axios.get(`/buildinginfo/${buildingID}`);
        setBuildingInfo(response.data.data[0]);
        setEditedBuildingInfo(response.data.data[0]);
      } catch (error) {
        console.error('Error fetching building info:', error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedBuildingInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const saveChanges = async () => {
    try {
      await axios.put(`/buildinginfo/${buildingID}`, editedBuildingInfo);
      console.log('Changes saved successfully');
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  if (!buildingInfo) {
    return <div>Loading...</div>;
  }

  return (
  <Container style={{  paddingTop: '20px', width: '100%' }}>
      <Typography variant="h4" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
        <img src='https://seeklogo.com/images/P/portsmouth-city-council-logo-17728273E5-seeklogo.com.png' alt='pcc logo' style={{ float: 'left', height: '50px' }} />
      <span style={{ textAlign: 'center', flex: '1' }}>Property Information</span>
      <img src={logo} alt='' style={{ float: 'right', height: '60px', marginLeft: '100px' }} />
    </Typography>
    <form>
      <div id={'top'}style={{ float: 'left', height: '150px', width: '100%', border: '1px solid blue'  }}>
      <div style={{ float: 'left', height: '150px', width: '600px', border: '1px solid red' }}>
      <div style={{ display: 'flex', alignItems: 'center',margin: '10px' }}>
      <div style={{ marginRight: '10px', width: '120px', textAlign: 'right' }}>
        UPRN:
      </div>
      <div style={{ width: '100px' }}>
        <TextField
        name="UPRN" value={editedBuildingInfo.UPRN} onChange={handleInputChange}
          label=""
          sx={{
            '& input': {
              backgroundColor: 'lightyellow',
              padding: '5px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              width: '100%', // Make sure the input takes the remaining space
            },
          }}
        />
      </div>
</div>
<div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
      
      <div style={{ marginRight: '10px', width: '120px', textAlign: 'right' }}>
        Name:
      </div>
      <div style={{ width: '400px',  border: '1px solid green' }}>
        <TextField
        name="propertyName" value={editedBuildingInfo.propertyName} onChange={handleInputChange}
          label=""
          sx={{
            '& input': {
              backgroundColor: 'lightyellow',
              padding: '5px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              width: '385px', // Make sure the input takes the remaining space
            },
          }}
        />
      </div>
</div>
<div style={{ display: 'flex', alignItems: 'center',margin: '10px' }}>
      <div style={{ marginRight: '10px', width: '120px', textAlign: 'right' }}>
        Address
      </div>
      <div style={{ width: '400px',  border: '1px solid green' }}>
      <TextField
  name="address"
  value={editedBuildingInfo.address}
  onChange={handleInputChange}
  label=""
  multiline
  rows={4} // Adjust the number of rows as needed
  sx={{
    '& textarea': { // Use textarea selector instead of input for multiline
      backgroundColor: 'lightyellow',
      padding: '5px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      width: '400px',
    },
  }}
/>      </div>
</div>
      </div>'  
<br></br>
        </div>  
        <TextField label="Building Manager" name="buildingManager" value={editedBuildingInfo.buildingManager} onChange={handleInputChange} fullWidth />
        <TextField label="EPC" name="EPC" value={editedBuildingInfo.EPC} onChange={handleInputChange} fullWidth />
        <TextField label="DEC" name="DEC" value={editedBuildingInfo.DEC} onChange={handleInputChange} fullWidth />
        <TextField label="Floor Area (m2)" name="floorAreaM2" type="number" value={editedBuildingInfo.floorAreaM2} onChange={handleInputChange} fullWidth />
        <TextField label="Built Year" name="builtYear" value={editedBuildingInfo.builtYear} onChange={handleInputChange} fullWidth />
        <TextField label="Planned Projects Value" name="plannedProjectsValue" type="number" value={editedBuildingInfo.plannedProjectsValue} onChange={handleInputChange} fullWidth />
        <TextField label="Description" name="description" value={editedBuildingInfo.description} onChange={handleInputChange} fullWidth />
        <Button variant="contained" color="primary" onClick={saveChanges}>Save Changes</Button>
      </form>

    </Container>
  );
}


export default BuildingInfoPage;
