// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Initialize isLoggedIn state with the value stored in localStorage, defaulting to false if not found
    return localStorage.getItem('isLoggedIn') === 'true';
  });
  const [userUUID, setUserUUID] = useState(() => {
    // Initialize userLevel state with the value stored in localStorage, defaulting to 'user' if not found
    console.log('getting userlevel')
    const ul = localStorage.getItem('userUUID'); 
    console.log('user UUID from storge', ul)
    return ul;
  });

  const [userLevel, setUserLevel] = useState(() => {
    // Initialize userLevel state with the value stored in localStorage, defaulting to 'user' if not found
    console.log('getting userlevel')
    const ul = localStorage.getItem('userLevel') || 'user'; 
    console.log('user level from storge', ul)
    return ul;
  });
  useEffect(() => {
    // Update localStorage whenever isLoggedIn state changes
    localStorage.setItem('userUUID', userUUID);
  }, [userUUID]);

  useEffect(() => {
    // Update localStorage whenever isLoggedIn state changes
    localStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    // Update localStorage whenever userLevel state changes
    console.log("Setting userlevel in local storage to ", userLevel)
    localStorage.setItem('userLevel', userLevel);
  }, [userLevel]);

  const login = (user) => {
    // Perform login logic here, and if successful, set isLoggedIn to true
    setIsLoggedIn(true);
    // Set user level based on your authentication logic
    console.log('Setting userlevel to ', user.level)
    setUserLevel(user.level); // Example: Set user level to 'admin'
    console.log('User UUID is', user.userUUID);
    setUserUUID(user.userUUID);
    console.log("Login Request");
  };

  const logout = () => {
    // Perform logout logic here, and if successful, set isLoggedIn to false
    setIsLoggedIn(false);
    setUserLevel('none');
    // Clear any user data or tokens from local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userLevel');
    localStorage.removeItem('userUUID');
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn,userLevel, userUUID, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);


