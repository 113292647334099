import React, { useState, useEffect } from 'react';
import { Container, Typography, Tabs, Tab, TextField, Button } from '@mui/material';
import api from "../api";

export function DynamicForm() {
  const [formData, setFormData] = useState(null);
  const [activePanel, setActivePanel] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.get(`dynamicform`);
      setFormData(response.data.ob);
    } catch (error) {
      console.error('Error fetching form data:', error);
    }
  };

  const handleChangePanel = (event, newValue) => {
    setActivePanel(newValue);
  };

  const handleSave = async () => {
    try {
      // Constructing the object from form fields
      const updatedFormData = {};
      Object.entries(formData).forEach(([key, value]) => {
        const field = document.getElementById(key);
        if (field && key !== 'Panels') {
          updatedFormData[key] = field.value;
        } else {
          updatedFormData[key] = value;
        }
      });
  
      updatedFormData.Panels.forEach((panel, index) => {
        Object.entries(panel.panelValues).forEach(([key]) => {
          const field = document.getElementById(`${key}-${index}`);
          if (field) {
            updatedFormData.Panels[index].panelValues[key] = field.value;
          }
        });
      });
      const json = JSON.stringify(updatedFormData);
//      await api.put('dynamicform', JSON.stringify(updatedFormData) );

      const res = await api.put('/dynamicform', json, {
        headers: {
          
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json'
        }
      });
      console.log(res);    


      console.log('Form data updated successfully:', updatedFormData);
    } catch (error) {
      console.error('Error updating form data:', error);
    }
  };
  
  
  return (
    <Container style={{ width: '100%', paddingTop: '20px' }}>
      {formData ? (
        <>
          <Typography variant="h4" gutterBottom>
            Form Data
          </Typography>
          {Object.entries(formData).map(([key, value]) => {
            if (key !== 'Panels') {
              return (
                <TextField
                  key={key}
                  id={key}
                  label={key}
                  defaultValue={value}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              );
            }
            return null;
          })}
          <Tabs
            value={activePanel}
            onChange={handleChangePanel}
            variant="scrollable"
            scrollButtons="auto"
          >
            {formData.Panels.map((panel, index) => (
              <Tab key={index} label={panel.panelName} />
            ))}
          </Tabs>
          {formData.Panels.map((panel, index) => (
            <div key={index} hidden={activePanel !== index}>
              {Object.entries(panel.panelValues).map(([key, value]) => (
                <TextField
                  key={key}
                  id={`${key}-${index}`}
                  label={key}
                  defaultValue={value}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              ))}
            </div>
          ))}
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      ) : (
        <Typography variant="body1" color="error">
          Error fetching form data
        </Typography>
      )}
    </Container>
  );
}

export default DynamicForm;
