// AuthButtons.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuth } from './AuthContext';

const AuthButtons = () => {
  const { isLoggedIn,userLevel, userUUID,logout } = useAuth();
  console.log('userlevel', userLevel, 'ls loogedin', isLoggedIn);

  return (
    <>
      {isLoggedIn && (userLevel === 'admin' || userLevel === 'superadmin') && <Button component={Link} to="/users" color="inherit">List Users</Button>}
      {isLoggedIn && (userLevel === 'admin' || userLevel === 'superadmin') && <Button component={Link} to="/suppliers" color="inherit">List Suppliers</Button>}
      {isLoggedIn && <Button component={Link} to="/properties" color="inherit">Property List</Button>}
      {isLoggedIn && <Button component={Link} to="/projects" color="inherit">Project List</Button>}
      {isLoggedIn && <Button component={Link} to={`/user/${userUUID}`} color="inherit">Edit Profile</Button>}
      <Button component={Link} to="/login" color="inherit" onClick={isLoggedIn ? logout : null}>
        {isLoggedIn ? 'Logout' : 'Login'}
      </Button>

    </>
  );
};

export default AuthButtons;
