/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';    
//axios.defaults.withCredentials = true;
// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    // OR config.headers.common['Authorization'] = `Bearer ${your_token}`;
    config.baseURL = process.env.REACT_APP_BASE_URL;
    return config;
  },
  function (error) {
    // Do something with request error
    console.log("We have an error we might be able to handle")
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
};