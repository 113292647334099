import React, { useContext, useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Button } from '@mui/material';
import api from "../api";
import { PropertyViewContext } from '../components/PropertyView';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import FileUploadForm from '../components/FileUploadForm'

const AssetTable = ({ propertyUUID, datasetName, fieldName }) => {
  const handleCloseUploadDialog = () => {

    setOpenUploadDialog(false);
    fetchData();
  };
  
  const handleOpenUploadDialog = () => {
    setOpenUploadDialog(true);
  };
  
  
  const [assets, setAssets] = useState([]);
  const state = useContext(PropertyViewContext);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [openUploadDialog, setOpenUploadDialog] = useState(false); // State to control file upload modal visibility

  const fetchData = async () => {
    try {
      const response = await api.get(`/assets/${propertyUUID}/${datasetName}/${fieldName}`);
      setAssets(response.data.data);
    } catch (error) {
      console.error('Error fetching assets:', error);
    }
  };

  const handleDelete = async (assetUUID) => {
    try {
      await api.delete(`/assets/${assetUUID}`);
      // Remove the deleted asset from the assets array
      setAssets(prevAssets => prevAssets.filter(asset => asset.asset_uuid !== assetUUID));
    } catch (error) {
      console.error('Error deleting asset:', error);
    }
  };

  return (
    <>          <Button onClick={handleOpenUploadDialog}>Upload</Button>

    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Asset Type</TableCell>
          <TableCell>Filename</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Timestamp</TableCell>
          <TableCell>Uploaded By</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {assets.map(asset => (
          <TableRow key={asset.asset_uuid}>
            <TableCell>{asset.asset_type}</TableCell>
            <TableCell>{asset.asset_file_name}</TableCell>
            <TableCell>{asset.asset_metadata.description}</TableCell>
            <TableCell>{asset.asset_metadata.timeStamp}</TableCell>
            <TableCell>{asset.uploadedby}</TableCell>
            <TableCell>
              <Button onClick={() => handleDelete(asset.asset_uuid)}>Delete</Button>
              {/* Add edit button functionality here */}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
     {/* File Upload Modal */}
 <Dialog open={openUploadDialog} onClose={handleCloseUploadDialog}>
 <DialogTitle>Upload File</DialogTitle>
 <DialogContent>
   <FileUploadForm fieldName={fieldName} dataSet={state.dataSet} propertyID={state.propertyID} />
 </DialogContent>
 <DialogActions>
   <Button onClick={handleCloseUploadDialog}>Cancel</Button>
 </DialogActions>
</Dialog>
</>

    
  );
};

export default AssetTable;
