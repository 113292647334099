
import React, { useContext, useState } from 'react';
import cameraImage from '../assets/camera.svg'; // Assuming camera.png is the image file
import ContentCopyIcon from '../assets/docs.png';
import NoteAddOutlinedIcon from '../assets/document-add.png';
import { PropertyViewContext } from '../components/PropertyView';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import AssetTable from '../components/AssetTable'
function calcPad(width, nopad) {
  if (nopad === true) {
    return 30;
  } else {
    return 400 - width;
  }
}

function TextInputfield({ name, nodocs, camera, value, onChange, disabled, label, width, nopad, backgroundColor, clear, docs }) {
  const state = useContext(PropertyViewContext);
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Use state in the component
  // For example:
  //console.log(state);
  const newwidth = width || 400;
  const padwidth = calcPad(newwidth, nopad);
  if (docs === 'none') {
    docs = false;
  };
  const assetsdialog = () => {
    // console.log({name}, state.dataSet, state.propertyID)
    setOpenDialog(true);
  };


  
  return (
    <div>
      <div style={{ width: `110px`, height: `40px`, float: 'left', marginRight: '5px', alignItems: 'center', textAlign: 'right' }}>{label}</div>
      <div style={{ width: `${newwidth}px`, textAlign: 'left', float: 'left' }}>
        <input
          type="text"
          disabled={disabled}
          value={value}
          name={name}
          onChange={onChange}
          style={{
            height: '40px',
            width: '100%',
            fontSize: '1.1em',
            borderRadius: '5px',
            border: '1px solid #ccc',
            boxSizing: 'border-box',
            padding: '5px',
            marginBottom: '10px',
            backgroundColor: backgroundColor,
          }}
        />
      </div>
      <div style={{ width: `${padwidth}px`, height: `40px`, paddingTop: '5px', textAlign: 'left', float: 'left' }}>
        {docs && (
          <img src={ContentCopyIcon} alt="Docs" onClick={(e) => camera(e, name)} />
        )}
        {disabled && camera && (
          <img src={cameraImage} alt="Photos" onClick={(e) => camera(e, name)} />
        )}
        {!disabled && !nodocs && (
          <img src={NoteAddOutlinedIcon} height='20px' alt="Add" onClick={() => assetsdialog(name)} />
        )}
      </div>
      {clear && (
        <div style={{ clear: 'both' }}></div>
      )}
       <div sx={{ minWidth: '900px' }}>
 <Dialog open={openDialog} onClose={handleCloseDialog} fullScreen>
 
        <DialogTitle>Assets for {label}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
                <DialogContent >
          <AssetTable propertyUUID={state.propertyID} datasetName={state.dataSet} fieldName={name} propertyID={state.propertyID}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
        
      </Dialog>
      </div>



    </div>
    
  );
}

export default TextInputfield;
