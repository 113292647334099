/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { TextArea } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { Container, AppBar, Tabs, Tab, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import api from '../api'; // Assuming api module is located in the '../api' directory
import MuiAlert from '@mui/material/Alert';
import TextInputField from '../uicomponents/TextInputField';
import SupplierProjectsGrid from './SupplierProjectsGrid';

//import SelectBox from '../uicomponents/SelectBox';
// import ImageCarouselPopup from './ImageCarouselPopup';
//import DataList from "./DataList"
//import ImagePopup from './ImagePopup';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/retrrologo.png';
//import TextareaAutosize from '@mui/material/TextareaAutosize';




const SupplierView = () => {

  
  const navigate = useNavigate();
  const location = useLocation();
  const { supplierUUID } = useParams();
  const [formData, setFormData] = useState({
      "supplierUUID": '',
      "supplierName": '',
      "supplierPrimaryContact": '',
      "supplierParentOrganisationUUI": '',
      "supplierAddressLine1": '',
      "supplierAddressLine2": '',
      "supplierCity": '',
      "supplierCounty": '',
      "supplierPostcode": '',
      "supplierCountry": '',
      "supplierReference": '',
      "primaryContactName": ''
  });
  
  
  const [userRights, setUserRights] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  

  


  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    try {
      const response = await api.get(`/supplier/${supplierUUID}`);
      const data = response.data.data;
      setFormData(data);
      setUserRights(response.data.rights);

    } catch (error) {
      console.error('Error fetching form data:', error);
      if (error.response && error.response.data && error.response.data.data === "token expired") {
        navigate('/login', { state: { from: location.pathname } });
      } else {
        setError('Error fetching data');
        console.log(error.response.data.data);
      }
    }
  };
  
  const handleInputChange = (e) => {

    const { name, value } = e.target;
    console.log(name);
    console.log(value);

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {

    fetchData()
    setEditMode(false);
  };


  const handleSave = async () => {
    try {
      api.put(`/supplier/${supplierUUID}`, formData);
      console.log("Dummy save project");
      setEditMode(false);
      console.log(formData)
      //console.log(formData)
      //fetchData();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };


  const handleDelete = async () => {
    setOpenDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`/supplier/${supplierUUID}`);
      setOpenDeleteModal(false);
      fetchData();
    } catch (error) {
      setOpenDeleteModal(false);
      setError('Error deleting data.');
      setSnackbarOpen(true);
    }
  };


  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Container style={{ paddingTop: '20px', width: '100%' }}>
      <Typography variant="h4" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
        <img src='https://seeklogo.com/images/P/portsmouth-city-council-logo-17728273E5-seeklogo.com.png' alt='pcc logo' style={{ float: 'left', height: '50px' }} />
        <span style={{ textAlign: 'center', flex: '1' }}>Supplier</span>
        <img src={logo} alt='' style={{ float: 'right', height: '60px', marginLeft: '100px' }} />
      </Typography>
      {error && (
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
      <div style={{ width: '100%', height: '100%', minHeight: '60px', marginBottom: '5px' }}>
        <div style={{ width: '540px', float: 'left' }}>
        <TextInputField
            name="supplierName"
            value={formData.supplierName}
            onChange={handleInputChange}
            label="Name"
            disabled={!editMode}
            width="400"
            backgroundColor={'lightyellow'}

          />


        </div>
        <div style={{ width: '500px', float: 'left' }}>

        <TextInputField
            name="supplierReference"
            value={formData.supplierReference}
            onChange={handleInputChange}
            label="Reference"
            disabled={!editMode}
            width="125"
            backgroundColor={'lightyellow'}
            clear
          />
          

          




        </div>
      </div>

      <AppBar position="static" sx={{ background: '#fff' }}>
        <Tabs value={tabValue} onChange={handleChangeTab}>
          <Tab label="Information" sx={{ backgroundColor: tabValue === 0 ? 'white' : '#f5f5f5' }} />
          <Tab label="Contacts" sx={{ backgroundColor: tabValue === 1 ? 'white' : '#f5f5f5' }} />
          <Tab label="Projects" sx={{ backgroundColor: tabValue === 2 ? 'white' : '#f5f5f5' }} />
          <Tab label="Notes" sx={{ backgroundColor: tabValue === 3 ? 'white' : '#f5f5f5' }} />
        </Tabs>
      </AppBar>
      {tabValue === 0 && (
        <>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
            <div style={{ paddingTop: '10px', width: '550px', float: 'left'}}>
            <TextInputField
            name="supplierAddressLine1"
            value={formData.supplierAddressLine1}
            onChange={handleInputChange}
            label="Address"
            disabled={!editMode}
            
          />
            <TextInputField
            name="supplierAddressLine2"
            value={formData.supplierAddressLine2}
            onChange={handleInputChange}
            label=""
            disabled={!editMode}
          />
            <TextInputField
            name="supplierCity"
            value={formData.supplierCity}
            onChange={handleInputChange}
            label=""
            disabled={!editMode}
          />
            <TextInputField
            name="supplierCounty"
            value={formData.supplierCounty}
            onChange={handleInputChange}
            label=""
            width="200"
            nopad
            clear
            disabled={!editMode}
          />
            <TextInputField
            name="supplierPostcode"
            value={formData.supplierPostcode}
            onChange={handleInputChange}
            label=""
            width="150"
            disabled={!editMode}
          />
            </div>
            <div style={{ paddingTop: '10px', width: '550px', float: 'left', minHeight: '450px' }}>
            <TextInputField
            name="primaryContactName"
            value={formData.primaryContactName}
            onChange={handleInputChange}
            label="Primary Contact"
            width="250"
            disabled={!editMode}
          />
              
              


            </div>
            
          </div>
        </>
      )}
      {tabValue === 1 && (
        <>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>
              
            



            </div>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>
           
           
            </div>
          </div>
        </>
      )}
      {tabValue === 2 && (
        <>
 <SupplierProjectsGrid supplierUUID={formData.supplierUUID} />
         </>
      )}

      {tabValue === 3 && (
        <>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
          <TextArea
              placeholder="Notes"
              label="Notes"
              style={{ marginTop: '10px', width: '99%', height: '400px' }}
              // Add any necessary props and event handlers here
              disabled={!editMode}
              value={formData.notes}
            />
          </div>
        </>
      )}
      {((userRights.includes('update') || userRights.includes('edit')) && !editMode) && (
        <Button variant="contained" color="primary" onClick={handleEdit}>Edit</Button>
      )}
      {editMode && (
        <>
          <Button variant="contained" onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </>
      )}
      {userRights.includes('delete') && (
        <Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
      )}
      <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>Deleting this item cannot be undone.</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">Yes</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SupplierView;

