/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, AppBar, Tabs, Tab, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import api from '../api'; // Assuming api module is located in the '../api' directory
import MuiAlert from '@mui/material/Alert';
import TextInputField from '../uicomponents/TextInputFieldProperty';
import SelectBox from '../uicomponents/SelectBox';

import ImagePopup from './ImagePopup';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/retrrologo.png';
import TextareaAutosize from '@mui/material/TextareaAutosize';


export const PropertyViewContext = createContext();


const PropertyForm = () => {
 
  const navigate = useNavigate();
  const location = useLocation();
  const { propertyID } = useParams();
  const [assetFlags, setAssetFlags] = useState({});
  // const [state, setState] = useState({ 
  const [state] = useState({
    formName : 'propertyView',
    dataSet : 'core',
    propertyID : propertyID
  });

  const [formData, setFormData] = useState({
    propertyName: '',
    UPRN: '',
    addressLine1: '',
    addressLine2: '', // Add this line
    city: '', // Add this line
    county: '', // Add this line
    postcode: '', // Add this line
    country: '',
    buildingManager: '',
    energyUse: '',
    assetFlags: {},
    energyRating: '',
    insulationScore: '',
    insulationRating: '',
    EPC: '', // Add this line
    epcExpiryDate: '', // Add this line
    DEC: '', // Add this line
    decExpiryDate: '', // Add this line
    floorAreaM2: '', 
    builtYear: '',
    plannedProjectsValue: '', // Add this line
    description: '',
    floorsOccupied: '',
    floorsUnoccupied: '',
    well: '', // Add this line
    wellExpiryDate: '', // Add this line
    energyStar: '',
    energyStarExpiryDate: '',
    reset: '',
    resetExpiryDate: '',
    leed: '',
    leedExpiryDate: '',
    classification: '',
    wallMaterials: '',
    roofMaterials: '',
    windows: '',
    smartGlass: '',
    glassPctShell: '',
    cladding: '',
    laggingInsulationWall: '',
    laggingInsulationRoof: '',
    contaminatiionReported: '',
    solarPanels: '',
    rainwaterCollection: '',
    aeratedTaps: '',
    greyWaterReclamation: '',
    lowFlushToilets: '',
    showerFacilities: '',
    waterPressureSmartControl: '',
    poELighting: '',
    smartBlinds: '',
    smartLighting: '',
    highEfficiencyRadiators: '',
    underFloorHeating: '',
    smartLiftControls: '',
    areaAtmosphericControl: '',
    ManagementSensors: '',
    autoAtmosControl: '',
    BEMSystems: '',
    CMMS: '',
    DigitalTwinBIM: '',
    doorSensors: '',
    electricitySubMetering: '',
    equipmentEnergySubMetering: '',
    fireSmokeMonitoring: '',
    flexibleSpaceBookingSoftware: '',
    hazardousMaterials: '',
    granularTemperatureMonitoring: '',
    headcountMonitoring: '',
    humidityMonitoring: '',
    IAQMonitoring: '',
    leakMonitoring: '',
    lightLevelMonitoring: '',
    motionSensors: '',
    occupancyMonitoring: '',
    predictiveAnalytics: '',
    singleScreenManagement: '',
    smartBIMS: '',
    tenantExperienceSW: '',
    voltageOptimisation: '',
    waterMeteringManagement: '',
    CCTV: '',
    entryControl: '',
    humanCentricLighting: '',
    waterQualityMonitoring: '',
    motionMonitoring: '',
    peopleCountingSensors: '',
    tenantCommsEngagementApp: '',
  });

  const [userRights, setUserRights] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [originalData, setOriginalData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showAssetsPopup, setShowAssetsPopup] = useState(false);
  const [propertyId, setPropertyId] = useState('');
  const [datasetId, setDatasetId] = useState('');
  const [fieldName, setFieldName] = useState('');
  const handleOpenAssetsPopup = (propid,fieldName) => {
    // Set the propertyId, datasetId, and fieldName
    // For example, you can get these values from somewhere else in your application state
    setPropertyId(propid);
    setDatasetId('core');
    setFieldName(fieldName);
    setShowAssetsPopup(true);
  };

  const handleClosePopup = () => {
    setShowAssetsPopup(false);
  };

  const handleAssetsClick = (e,inputName) => {
    console.log(propertyID + "_" + inputName);
   handleOpenAssetsPopup(propertyID,inputName)
  };
  

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    try {
      const response = await api.get(`/buildinginfo/${propertyID}`);
      const data = response.data.data.payload;
      setFormData(data);
      setUserRights(response.data.data.rights);
      setAssetFlags(response.data.data.assetFlags)
      setOriginalData(data); // Store original data
    } catch (error) {
      console.error('Error fetching form data:', error);
      if (error.response && error.response.data && error.response.data.data === "token expired") {
        navigate('/login', { state: { from: location.pathname } });
      } else {
        setError('Error fetching data');
        console.log(error.response.data.data);
      }
    }
  };

  const handleInputChange = (e) => {

    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  
};

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setFormData(originalData); // Restore original data
  };


  const handleSave = async () => {
    try {
      await api.put(`/buildinginfo/${propertyID}`, formData);
      setEditMode(false);
      setOriginalData(formData); // Update original data
      fetchData();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };


  const handleDelete = async () => {
    setOpenDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`/propinfo/${propertyID}`);
      setOpenDeleteModal(false);
      fetchData();
    } catch (error) {
      setOpenDeleteModal(false);
      setError('Error deleting data.');
      setSnackbarOpen(true);
    }
  };


  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <PropertyViewContext.Provider value={state}>
    <Container style={{ paddingTop: '20px', width: '100%' }}>
      <Typography variant="h4" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
        <img src='https://seeklogo.com/images/P/portsmouth-city-council-logo-17728273E5-seeklogo.com.png' alt='pcc logo' style={{ float: 'left', height: '50px' }} />
        <span style={{ textAlign: 'center', flex: '1' }}>Property Information</span>
        <img src={logo} alt='' style={{ float: 'right', height: '60px', marginLeft: '100px' }} />
      </Typography>
      {error && (
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
      <div style={{ width: '100%', height: '100%', minHeight: '60px', marginBottom: '5px' }}>
        <div style={{ width: '540px', float: 'left' }}>
          <TextInputField
            name="propertyName"
            value={formData.propertyName}
            docs={assetFlags.propertyName}
            onChange={handleInputChange}
            label="Name"
            disabled={!editMode}
            width="400"
            backgroundColor={'lightyellow'}
            
          />
          <TextInputField
            name="buildingManager"
            value={formData.buildingManager}
            onChange={handleInputChange}
            label="Manager"
            disabled={!editMode}
            width="200"
            backgroundColor="lightyellow"
            nopad
            clear
          />
          <TextInputField
            name="postcode"
            value={formData.postcode}
            onChange={handleInputChange}
            label="Postcode"
            disabled={!editMode}
            width="110"
            backgroundColor="lightyellow"
            nopad
          />
        </div>
        <div style={{ width: '500px', float: 'left' }}>
        <TextInputField
            name="uprn"
            value={formData.UPRN}
            onChange={handleInputChange}
            label="UPRN"
            disabled={!editMode}
            width="120"
            backgroundColor="lightyellow"
            nopad
            clear
          />

          <TextInputField
            name="plannedProjectsValue"
            value={formData.plannedProjectsValue}
            onChange={handleInputChange}
            label="PPV"
            disabled={!editMode}
            width="120"
            backgroundColor="lightyellow"
            nopad
            clear
          />
            <TextInputField
            name="classification"
            value={formData.classification}
            onChange={handleInputChange}
            label="Class"
            disabled={!editMode}
            width="300"
            nopad
            backgroundColor="lightyellow"
            
          />


        </div>
      </div>

      <AppBar position="static" sx={{ background: '#fff' }}>
        <Tabs value={tabValue} onChange={handleChangeTab}>
          <Tab label="Information" sx={{ backgroundColor: tabValue === 0 ? 'white' : '#f5f5f5' }} />
          <Tab label="Building Fabric" sx={{ backgroundColor: tabValue === 1 ? 'white' : '#f5f5f5' }} />
          <Tab label="Facilities" sx={{ backgroundColor: tabValue === 2 ? 'white' : '#f5f5f5' }} />
          <Tab label="Prop Tech" sx={{ backgroundColor: tabValue === 3 ? 'white' : '#f5f5f5' }} />
          <Tab label="Tenant Tech" sx={{ backgroundColor: tabValue === 4 ? 'white' : '#f5f5f5' }} />

        </Tabs>
      </AppBar>
      {tabValue === 0 && (
        <>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
            <div style={{ paddingTop: '10px', width: '550px', float: 'left', minHeight: '450px' }}>
<h3>Core Data</h3>
              <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px', margin: '10px', margintop: '90px', height: '100px' }}>
                <div style={{ marginTop: '-80px', marginRight: '10px', width: '100px', textAlign: 'right' }}>
                  Description
                </div>
                <div style={{ width: '454px' }}>
                  <TextareaAutosize
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    maxRows={5} // Number of visible rows
                    cols={80} // Number of visible columns
                    disabled={!editMode}
                    style={{
                      padding: '5px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      width: '100%',
                      height: '100px',
                      resize: 'none', // Disable resizing
                      overflowY: 'auto', // Show scrollbars if content overflows
                    }}
                  />
                </div>

              </div>
              <TextInputField
                name="addressLine1"
                value={formData?.addressLine1}
                onChange={handleInputChange}
                label="Address"
                disabled={!editMode}
              />
              <TextInputField
                name="addressLine2"
                value={formData.addressLine2}
                onChange={handleInputChange}
                label="Line 2"
                disabled={!editMode}
              />
              <TextInputField
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                label="City"
                disabled={!editMode}
                width="140"
                nopad
              />
              <TextInputField
                name="county"
                value={formData.county}
                onChange={handleInputChange}
                label="County"
                disabled={!editMode}
                width="114"
                nopad
                clear
              />

              <TextInputField
                name="postcode"
                value={formData.postcode}
                onChange={handleInputChange}
                label="Postcode"
                disabled={!editMode}
                width="110"
                nopad
                clear
              />
              <TextInputField
                name="floorAreaM2"
                value={formData.floorAreaM2}
                onChange={handleInputChange}
                label="Floor Area"
                disabled={!editMode}
                width="90"
                clear
              />
              <TextInputField
                name="floorsOccupied"
                value={formData.floorsOccupied}
                onChange={handleInputChange}
                label="Occupied Floors"
                disabled={!editMode}
                width="40"
                nopad
              />
              <TextInputField
                name="floorsUnoccupied"
                value={formData.floorsUnoccupied}
                onChange={handleInputChange}
                label="Unoccupied Floors"
                disabled={!editMode}
                width="40"
                nopad
              />

            </div>
            <div style={{ paddingTop: '10px', width: '550px', float: 'left', minHeight: '450px' }}>
              <h3>Certifications</h3>
              <TextInputField
                name="EPC"
                value={formData.EPC}
                onChange={handleInputChange}
                label="EPC"
                disabled={!editMode}
                width="40"
                color="#ffffbf"
                nopad
              />

              <TextInputField
                name="epcExpiryDate"
                value={formData.epcExpiryDate}
                onChange={handleInputChange}
                label="Expiry date"
                disabled={!editMode}
                width="120"
                color="#ffffbf"
                nopad
                clear
              />
              <TextInputField
                name="DEC"
                value={formData.DEC}
                onChange={handleInputChange}
                label="DEC"
                disabled={!editMode}
                width="40"
                color="#ffffbf"
                nopad
              />

              <TextInputField
                name="decExpiryDate"
                value={formData.decExpiryDate}
                onChange={handleInputChange}
                label="Expiry date"
                disabled={!editMode}
                width="120"
                color="#ffffbf"
                nopad
                clear
              />

              <TextInputField
                name="well"
                value={formData.well}
                onChange={handleInputChange}
                label="Well"
                disabled={!editMode}
                width="40"
                color="#ffffbf"
                nopad
              />
              <TextInputField
                name="wellExpiryDate"
                value={formData.wellExpiryDate}
                onChange={handleInputChange}
                label="Expiry date"
                disabled={!editMode}
                width="120"
                color="#ffffbf"
                nopad
                clear
              />

              <TextInputField
                name="energyStar"
                value={formData.energyStar}
                onChange={handleInputChange}
                label="Energystar"
                disabled={!editMode}
                width="40"
                color="#ffffbf"
                nopad
              />

              <TextInputField
                name="energyStarExpiryDate"
                value={formData.energyStarExpiryDate}
                onChange={handleInputChange}
                label="Expiry date"
                disabled={!editMode}
                width="120"
                nopad
                clear
              />
              <TextInputField
                name="reset"
                value={formData.reset}
                onChange={handleInputChange}
                label="Reset"
                disabled={!editMode}
                width="40"
                nopad
              />

              <TextInputField
                name="resetExpiryDate"
                value={formData.resetExpiryDate}
                onChange={handleInputChange}
                label="Expiry date"
                disabled={!editMode}
                width="120"
                nopad
                clear
              />
              <TextInputField
                name="leed"
                value={formData.leed}
                onChange={handleInputChange}
                label="Leed"
                disabled={!editMode}
                width="40"
                nopad
              />

              <TextInputField
                name="leedExpiryDate"
                value={formData.leedExpiryDate}
                onChange={handleInputChange}
                label="Expiry date"
                disabled={!editMode}
                width="120"
                nopad
                clear
              />

            </div>
          </div>
        </>
      )}
      {tabValue === 1 && (
        <>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>
              <h3>Building Fabric</h3>
              <TextInputField
                name="wallMaterials"
                value={formData.wallMaterials}
                onChange={handleInputChange}
                label="Wall Materials"
                disabled={!editMode}
                camera={assetFlags.wallMaterialsMaterials ? handleAssetsClick : undefined}
                />
              <TextInputField
                name="roofMaterials"
                value={formData.roofMaterials}
                onChange={handleInputChange}
                label="Roof  Materials"
                disabled={!editMode}
                camera={assetFlags.roofMaterials ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="windows"
                value={formData.windows}
                onChange={handleInputChange}
                label="Windows"
                disabled={!editMode}
               
                camera={assetFlags.windows ? handleAssetsClick : undefined}
              />
              <TextInputField
                name="smartGlass"
                value={formData.smartGlass}
                onChange={handleInputChange}
                label="Smart Glass"
                disabled={!editMode}
                camera={assetFlags.smartGlass ? handleAssetsClick : undefined}
                nopad
                width="100"
              />
              <TextInputField
                name="glassPctShell"
                value={formData.glassPctShell}
                onChange={handleInputChange}
                label="Shell Glass %"
                disabled={!editMode}
                camera={assetFlags.glassPctShell ? handleAssetsClick : undefined}
                width="40"
                nopad
                clear
              />
              <TextInputField
                name="cladding"
                value={formData.cladding}
                onChange={handleInputChange}
                label="Cladding"
                disabled={!editMode}
                camera={assetFlags.cladding ? handleAssetsClick : undefined}
                width="100"
                nopad
              />
              <TextInputField
                name="laggingwall"
                value={formData.laggingInsulationWall}
                onChange={handleInputChange}
                label="Lagging insulation wall"
                disabled={!editMode}
                camera={assetFlags.laggingInsulationWall ? handleAssetsClick : undefined}
                width="100"
                nopad
              />
              <TextInputField
                name="laggingInsulationRoof"
                value={formData.laggingInsulationRoof}
                onChange={handleInputChange}
                label="Lagging insulation roof"
                disabled={!editMode}
                camera={assetFlags.laggingInsulationRoof ? handleAssetsClick : undefined}
                width="200"
              />






            </div>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>
            <div style={{height:'55px'}}></div> 
              
              <SelectBox
                label="Hazardous Material"
                name="hazardousMaterials"
                options={[
                  { value: 'none', label: 'None' },
                  { value: 'minor', label: 'Minor instances' },
                  { value: 'significant', label: 'Significant instances' },
                ]}
                value={formData.hazardousMaterials}
                onChange={handleInputChange}
                disabled={!editMode}
                backgroundColor="#f4f4f4"
                camera={assetFlags.hazardousMaterials ? handleAssetsClick : undefined}
                width="250"
                clear
      />
              <TextInputField
                name="contaminationReported"
                value={formData.contaminationReported}
                onChange={handleInputChange}
                label="Contamination"
                disabled={!editMode}
                camera={assetFlags.contaminatiionReported ? handleAssetsClick : undefined}

              />

            </div>
          </div>
        </>
      )}
      {tabValue === 2 && (
        <>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>

              <h3>Internal Facilities</h3>
              <TextInputField
                name="aeratedTaps"
                value={formData.aeratedTaps}
                onChange={handleInputChange}
                label="Aerated taps"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.aeratedTaps ? handleAssetsClick : undefined}
              />
              <TextInputField
                name="greyWaterReclamation"
                value={formData.greyWaterReclamation}
                onChange={handleInputChange}
                label="Grey water reclamation"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.greyWaterReclamation ? handleAssetsClick : undefined}


              />
              <TextInputField
                name="lowFlushToilets"
                value={formData.lowFlushToilets}
                onChange={handleInputChange}
                label="Low-flush toilets"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.lowFlushToilets ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="showerFacilities"
                value={formData.showerFacilities}
                onChange={handleInputChange}
                label="Shower facilities"
                disabled={!editMode}
                width='100'
                camera={assetFlags.showerFacilities ? handleAssetsClick : undefined}
                nopad

              />
              <TextInputField
                name="waterPressureSmartControl"
                value={formData.waterPressureSmartControl}
                onChange={handleInputChange}
                label="Water pressure smart control"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.waterPressureSmartControl ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="poeLighting"
                value={formData.poELighting}
                onChange={handleInputChange}
                label="PoE Lighting"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.poELighting ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="smartBlinds"
                value={formData.smartBlinds}
                onChange={handleInputChange}
                label="Auto/Smart Blinds"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.smartBlinds ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="smartLighting"
                value={formData.smartLighting}
                onChange={handleInputChange}
                label="Smart Lighting"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.smartLighting ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="highEfficiencyRadiators"
                value={formData.highEfficiencyRadiators}
                onChange={handleInputChange}
                label="High efficiency radiators"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.highEfficiencyRadiators ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="underFloorHeating"
                value={formData.underFloorHeating}
                onChange={handleInputChange}
                label="Underfloor heating"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.underFloorHeating? handleAssetsClick : undefined}

              />

              <TextInputField
                name="smartLiftControls"
                value={formData.smartLiftControls}
                onChange={handleInputChange}
                label="Smart lift controls"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.smartLiftControls ? handleAssetsClick : undefined}

              />

            </div>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>


              <h3>External Facilities</h3>
              <TextInputField
                name="solarPanels"
                  value={formData.solarPanels}
                onChange={handleInputChange}
                label="Solar collection panels"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.solarPanels ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="rainwaterCollection"
                value={formData.rainwaterCollection}
                onChange={handleInputChange}
                label="Rainwater collection"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.rainwaterCollection ? handleAssetsClick : undefined}

              />

            </div>
          </div>
        </>
      )}

      {tabValue === 3 && (
        <>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>
            <h3>Smart Technology</h3>
     
              <TextInputField
                name="areaAtmosphericControl"
                value={formData.areaAtmosphericControl}
                onChange={handleInputChange}
                label="Area atmos control"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.areaAtmosphericControl ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="assetManagementSensors"
                value={formData.assetManagementSensors}
                onChange={handleInputChange}
                label="Asset Mgt Sensors"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.assetManagementSensors ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="autoAtmosControl"
                value={formData.autoAtmosControl}
                onChange={handleInputChange}
                label="Auto Atmos Control"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.autoAtmosControl ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="smartGlass"
                value={formData.smartGlass}
                onChange={handleInputChange}
                label="Bem System"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.smartGlass ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="CMMS"
                value={formData.CMMS}
                onChange={handleInputChange}
                label="CMMS"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.CMMS ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="DigitalTwinBIM"
                value={formData.DigitalTwinBIM}
                onChange={handleInputChange}
                label="Digital twin BIM"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.DigitalTwinBIM ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="doorSensors"
                value={formData.doorSensors}
                onChange={handleInputChange}
                label="Door Sensors"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.doorSensors ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="electricitySubMetering"
                value={formData.electricitySubMetering}
                onChange={handleInputChange}
                label="Elect sub metering"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.electricitySubMetering ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="equipmentEnergySubMetering"
                value={formData.equipmentEnergySubMetering}
                onChange={handleInputChange}
                label="Eqpt plant sub metering"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.equipmentEnergySubMetering ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="fireSmokeMonitoring"
                value={formData.fireSmokeMonitoring}
                onChange={handleInputChange}
                label="Fire/Smoke Monitoring"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.fireSmokeMonitoring ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="flexibleSpaceBookingSoftware"
                value={formData.flexibleSpaceBookingSoftware}
                onChange={handleInputChange}
                label="Space booking s/w"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.flexibleSpaceBookingSoftware ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="granularTemperatureMonitoring"
                value={formData.granularTemperatureMonitoring}
                onChange={handleInputChange}
                label="Granular temp monitoring"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.granularTemperatureMonitoring ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="headcountMonitoring"
                value={formData.headcountMonitoring}
                onChange={handleInputChange}
                label="Headcount monitoring"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.headcountMonitoring ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="humidityMonitoring"
                value={formData.humidityMonitoring}
                onChange={handleInputChange}
                label="Humidity monitoring"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.humidityMonitoring ? handleAssetsClick : undefined}


              />






            </div>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>
              <div style={{height:'55px'}}></div> 
              <TextInputField
                name="IAQMonitoring"
                value={formData.IAQMonitoring}
                onChange={handleInputChange}
                label="IAQ monitoring"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.IAQMonitoring ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="leakMonitoring"
                value={formData.leakMonitoring}
                onChange={handleInputChange}
                label="Leak monitoring"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.leakMonitoring ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="lightLevelMonitoring"
                value={formData.lightLevelMonitoring}
                onChange={handleInputChange}
                label="Light level monitoring"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.lightLevelMonitoring ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="motionSensors"
                value={formData.motionSensors}
                onChange={handleInputChange}
                label="Motion sensors"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.motionSensors ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="occupancyMonitoring"
                value={formData.occupancyMonitoring}
                onChange={handleInputChange}
                label="Occupancy monitoring"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.occupancyMonitoring ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="predictiveAnalytics"
                value={formData.predictiveAnalytics}
                onChange={handleInputChange}
                label="Predictive Analytics"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.predictiveAnalytics ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="singleScreenManagement"
                value={formData.singleScreenManagement}
                onChange={handleInputChange}
                label="Single screen management"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.singleScreenManagement ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="smartBIMS"
                value={formData.smartBIMS}
                onChange={handleInputChange}
                label="Smart BMS"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.smartBIMS ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="tenantExperienceSW"
                value={formData.tenantExperienceSW}
                onChange={handleInputChange}
                label="Tenant Exp S/W"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.tenantExperienceSW ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="voltageOptimisation"
                value={formData.voltageOptimisation}
                onChange={handleInputChange}
                label="Voltage Optimisation"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.voltageOptimisation ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="waterMeteringManagement"
                value={formData.waterMeteringManagement}
                onChange={handleInputChange}
                label="Water metering & management"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.waterMeteringManagement ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="waterQualityMonitoring"
                value={formData.waterQualityMonitoring}
                onChange={handleInputChange}
                label="Water Quality Monitoring"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.waterQualityMonitoring? handleAssetsClick : undefined}

              />

            </div>
          </div>
        </>
      )}
      {tabValue === 4 && (
        <>
        <h3>Tenant Technology</h3>
          <div style={{ width: '100%', float: 'left', minHeight: '450px' }}>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>
              <TextInputField
                name="CCTV"
                value={formData.CCTV}
                onChange={handleInputChange}
                label="CCTV"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.CCTV ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="entryControl"
                value={formData.entryControl}
                onChange={handleInputChange}
                label="Entry Control"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.entryControl ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="humanCentricLighting"
                value={formData.humanCentricLighting}
                onChange={handleInputChange}
                label="Human Centric Lighting"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.humanCentricLighting ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="motionMonitoring"
                value={formData.motionMonitoring}
                onChange={handleInputChange}
                label="Motion Monitoring"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.motionMonitoring ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="peopleCountingSensors"
                value={formData.peopleCountingSensors}
                onChange={handleInputChange}
                label="People counting sensors"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.peopleCountingSensors ? handleAssetsClick : undefined}

              />
              <TextInputField
                name="tenantCommsEngagementApp"
                value={fetchData.tenantCommsEngagementApp}
                onChange={handleInputChange}
                label="Tenant engagement app"
                disabled={!editMode}
                width='100'
                nopad
                camera={assetFlags.tenantCommsEngagementApp ? handleAssetsClick : undefined}

              />




            </div>
            <div style={{ width: '550px', float: 'left', minHeight: '450px', paddingTop: '10px' }}>

            </div>
          </div>
        </>
      )}
      {((userRights.includes('update') || userRights.includes('edit')) && !editMode) && (
        <Button variant="contained" color="primary" onClick={handleEdit}>Edit</Button>
      )}
      {editMode && (
        <>
          <Button variant="contained" onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </>
      )}
      {userRights.includes('delete') && (
        <Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
      )}
       
       <Dialog open={showAssetsPopup} onClose={handleClosePopup}>
        <DialogContent>
          <ImagePopup propertyId={propertyId} datasetId={datasetId} fieldName={fieldName} onClose={handleClosePopup} />
        </DialogContent>
      </Dialog>
      <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>Deleting this item cannot be undone.</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">Yes</Button>
        </DialogActions>
      </Dialog>
    </Container>
    </PropertyViewContext.Provider>
  );
};

export default PropertyForm;

