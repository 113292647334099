import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, MenuItem, FormControl, InputLabel, Select, IconButton, InputAdornment, OutlinedInput, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import api from '../api';

const AddUserForm = ({ onClose, mode, userData }) => { // Receive mode and userData props
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    userType: 'user', // Default user type
  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // If in edit mode, populate form data with user data
    if (mode === 'edit' && userData) {
      setFormData({
        firstName: userData.first_name || '',
        lastName: userData.last_name || '',
        email: userData.user_email || '',
        password: '', // Don't populate password field in edit mode
        userType: userData.user_type || 'user', // Default to 'user' if user_type is not provided
      });
    }
  }, [mode, userData]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Make API call to add/edit user
    const requestData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      user_email: formData.email,
      password: formData.password,
      user_type: formData.userType, // Add user_type to the request data
    };

    const apiEndpoint = mode === 'edit' ? `/user/${userData.id}` : '/user'; // Determine API endpoint based on mode
    const requestMethod = mode === 'edit' ? 'put' : 'post'; // Determine HTTP method based on mode

    api[requestMethod](apiEndpoint, requestData)
      .then(response => {
        if (response.data.status === 'success') {
          onClose(); // Close the modal
        } else {
          console.log(response.data.message)  
          setError('Failed to save user: '+ response.data.message); // Handle error if API call fails
        }
      })
      .catch(error => {
        setError('Error saving user');
        console.error('Error saving user:', error);
      });
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: '1rem' }}>
      <Typography variant="h6" gutterBottom>
        {mode === 'edit' ? 'Edit User' : 'Add User'} {/* Display appropriate title based on mode */}
      </Typography>
      <TextField
        label="First Name"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        fullWidth
        required
      />
      <TextField
        label="Last Name"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        fullWidth
        required
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        fullWidth
        required
      />
      <FormControl fullWidth variant="outlined" margin="normal" required>
        <InputLabel>User Type</InputLabel>
        <Select
          label="User Type"
          name="userType"
          value={formData.userType}
          onChange={handleChange}
        >
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="superadmin">Super Admin</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined" margin="normal" required>
        <InputLabel>Password</InputLabel>
        <OutlinedInput
          name="password"
          type={showPassword ? 'text' : 'password'}
          value={formData.password}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handlePasswordVisibility}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        <FormHelperText>Show password</FormHelperText>
      </FormControl>
      {error && <Typography variant="body1" color="error">{error}</Typography>}
      <Button type="submit" variant="contained" color="primary" style={{ marginTop: '1rem' }}>
        {mode === 'edit' ? 'Save' : 'Add'} {/* Display appropriate button label based on mode */}
      </Button>
      <Button onClick={onClose} variant="outlined" color="primary" style={{ marginTop: '1rem', marginLeft: '0.5rem' }}>
        Cancel {/* Add cancel button */}
      </Button>
    </form>
  );
};

export default AddUserForm;
