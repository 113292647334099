import React, { useState } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';

const dateFromYYYYMMDDString = (dateString) => {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day);
};

const differenceDays = (date1, date2) => {
  const differenceInMilliseconds = date1 - date2;
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  return differenceInDays;
};

const dateComparedToToday = (dateString) => {
  const givenDate = dateFromYYYYMMDDString(dateString);
  const today = new Date();
  return differenceDays(givenDate, today);
};

const StatusBox = ({ status }) => {
  let color;
  if (typeof status === "undefined") {
    return <div></div>;
  }
  if (status.plannedCompletionDate === '') {
    color = 'lightgray';
  } else {
    color = 'green'
    if (status.plannedCompletionDate.length === 10) {
      const differenceInDays = dateComparedToToday(status.plannedCompletionDate);
      if (status.actualCompletionDate === '') {
        if (differenceInDays < 0) {
          color = 'red';
        } else {
          color = '#FFFFE0';
        }
      } else {
        const diff = differenceDays(dateFromYYYYMMDDString(status.actualCompletionDate), dateFromYYYYMMDDString(status.plannedCompletionDate));
        if (diff > 0) {
          color = 'yellow';
        }
      }
    }
  }
  return <div style={{ width: '30px', height: '30px', backgroundColor: color, display: 'inline-block', marginRight: '10px' }}></div>;
};

const DataList = ({ data, editMode }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [editedActualDate, setEditedActualDate] = useState(null);
  const [editedPlannedDate, setEditedPlannedDate] = useState(null);

  const handleEditClick = (index) => {
    setSelectedRow(index);
    setEditedActualDate(data[index].actualCompletionDate);
    setEditedPlannedDate(data[index].plannedCompletionDate);
  };

  const handleClosePopup = () => {
    setSelectedRow(null);
    setEditedActualDate(null);
    setEditedPlannedDate(null);
  };

  const handleActualDateChange = (e) => {
    setEditedActualDate(e.target.value);
  };

  const handlePlannedDateChange = (e) => {
    setEditedPlannedDate(e.target.value);
  };

  const handleSaveChanges = () => {
    const newData = [...data];
    newData[selectedRow].actualCompletionDate = editedActualDate;
    newData[selectedRow].plannedCompletionDate = editedPlannedDate;
    // Update the status row in the parent component
    // You can define a function in the parent component and pass it down as a prop to update the data
    setSelectedRow(null);
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th style={{ width: '150px', textAlign: 'right' }}>Status</th>
            <th></th>
            <th style={{ width: '100px', textAlign: 'center' }}>Actual Completion Date</th>
            <th style={{ width: '100px', textAlign: 'center' }}>Planned Completion Date</th>
            {editMode && <th>Edit</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td style={{ width: '150px', textAlign: 'right' }}>{item.status}</td>
              <td><StatusBox width='50px' status={item} /></td>
              <td style={{ width: '100px', textAlign: 'center' }}>
                {editMode && selectedRow === index ?
                  <input type="date" value={editedActualDate || ""} onChange={handleActualDateChange} />
                  :
                  item.actualCompletionDate
                }
              </td>
              <td style={{ width: '100px', textAlign: 'center' }}>
                {editMode && selectedRow === index ?
                  <input type="date" value={editedPlannedDate || ""} onChange={handlePlannedDateChange} />
                  :
                  item.plannedCompletionDate
                }
              </td>
              {editMode && <td><button onClick={() => handleEditClick(index)}>Edit</button></td>}
            </tr>
          ))}
        </tbody>
      </table>
      {selectedRow !== null && (
        <Modal open={selectedRow !== null} onClose={handleClosePopup} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, minWidth: 300 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Row
            </Typography>
            <Box>
              <p>STATUS: {data[selectedRow].status}</p>
              <p>
                ActualCompletion:
                <input type="date" value={editedActualDate || ""} onChange={handleActualDateChange} />
              </p>
              <p>
                Planned Completion:
                <input type="date" value={editedPlannedDate || ""} onChange={handlePlannedDateChange} />
              </p>
              <Button variant="contained" onClick={handleSaveChanges}>Save Changes</Button>
              <Button variant="outlined" onClick={handleClosePopup} sx={{ ml: 2 }}>Close</Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default DataList;
