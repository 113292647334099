import React, { useState, useEffect } from 'react';
import api from '../api';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditUserForm = () => {
    const { userID } = useParams();
    const { isLoggedIn, userLevel, userUUID } = useAuth();
    const navigate = useNavigate();
    console.log(isLoggedIn);
    const [user, setUser] = useState(null);
    console.log(user);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        userType: ''
    });
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        api.get(`/user/${userID}`)
            .then(response => {
                if (response.data.status === 'success') {
                    setUser(response.data.data);
                    setFormData({
                        firstName: response.data.data.first_name,
                        lastName: response.data.data.last_name,
                        email: response.data.data.user_email,
                        userType: response.data.data.user_type
                    });
                } else {
                    displaySnackbar('Failed to fetch user data', 'error');
                }
            })
            .catch(error => {
                displaySnackbar('Error fetching user data', 'error');
                console.error('Error fetching user data:', error);
            });
    }, [userID]);

    const handleClose = () => {
        navigate(-1);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handlePasswordChangeClick = () => {
        setShowPasswordFields(!showPasswordFields);
        // Clear password fields when hiding the change password section
        if (!showPasswordFields) {
            setNewPassword('');
            setConfirmPassword('');
            setCurrentPassword('');
        }
    };

    const handlePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            displaySnackbar('Passwords do not match', 'error');
            return;
        }

        const updatedFormData = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            user_email: formData.email,
            user_type: formData.userType
        };

        // Include new password in the payload if it's set and matches the confirm password
        if (newPassword && newPassword === confirmPassword) {
            updatedFormData.password = newPassword;
            // If user is editing their own profile, include current password
            if (userID === userUUID && currentPassword) {
                updatedFormData.currentPassword = currentPassword;
            }
        }

        api.put(`/user/${userID}`, updatedFormData)
            .then(response => {
                if (response.data.status === 'success') {
                    displaySnackbar('Saved!', 'success');
                } else {
                    displaySnackbar('Failed to update user data', 'error');
                }
            })
            .catch(error => {
                displaySnackbar('Error updating user data', 'error');
                console.error('Error updating user data:', error);
            });
    };

    const displaySnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    return (
        <div style={{ textAlign: 'center',  }}>
                <h1>Edit User</h1>
            
        <div style={{ display: 'flex', justifyContent: 'center', width: '500px', margin: 'auto' }}>
                        
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <TextField
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                <TextField
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                <TextField
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                <Select
                    label="User Type"
                    name="userType"
                    value={formData.userType}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    disabled={userLevel !== 'admin' && userLevel !== 'superadmin'}
                >
                    <MenuItem value="superadmin">Super Admin</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="user">User</MenuItem>
                </Select>
                {showPasswordFields &&
                    <>
                        <TextField
                            label="New Password"
                            name="newPassword"
                            type={passwordVisibility ? 'text' : 'password'}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handlePasswordVisibility} edge="end">
                                            {passwordVisibility ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Confirm Password"
                            name="confirmPassword"
                            type={passwordVisibility ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handlePasswordVisibility} edge="end">
                                            {passwordVisibility ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {userID === userUUID &&
                            <TextField
                                label="Current Password"
                                name="currentPassword"
                                type={passwordVisibility ? 'text' : 'password'}
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handlePasswordVisibility} edge="end">
                                                {passwordVisibility ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        }
                    </>
                }
                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '1rem' }}>
                    Save Changes
                </Button>
                <Button onClick={handleClose} variant="outlined" color="primary" style={{ marginTop: '1rem', marginLeft: '0.5rem' }}>
                    Close
                </Button>
                <Button onClick={handlePasswordChangeClick} variant="outlined" color="secondary" style={{ marginTop: '1rem', marginLeft: '0.5rem' }}>
                    {showPasswordFields ? 'Hide Change Password' : 'Change Password'}
                </Button>
            </form>
        </div>
        </div>
    );
};

export default EditUserForm;
